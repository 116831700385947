<template>
  <div>
    <!-- Contenedor Bienvenida -->
    <div class="bienvenida-container">
      <img
        :src="separadorImg"
        alt="Separador"
        class="separador-img"
      >
      <div class="overlay">
        <div
          ref="tex1Ref"
          class="tex1"
        >
         <br> Código de Vestimenta
        </div>
      </div>

      <!-- Segundo Overlay debajo del primero -->
      <div
        ref="bienvenidaRef"
        class="overlay2"
      >
      </div>

      <div
        ref="text2Ref"
        class="text2"
      >
       <br> Formal
      </div>
      <div
        ref="text3Ref"
        class="text3"
      >
        <br> Te sugerimos unas ideas <br>de pinterest <i class="fas fa-heart" />:
      </div>
    </div>

    <!-- Contenedor del collage -->
    <div
      ref="collageRef"
      class="collage"
    >
      <div class="image-item">
        <!-- Primer iframe de Pinterest -->
        <iframe
          src="https://assets.pinterest.com/ext/embed.html?id=581738476897743180"
          height="582"
          width="345"
          frameborder="0"
          scrolling="no"
          class="collage-image"
        />
      </div>
      <div class="image-item">
        <!-- Segundo iframe de Pinterest -->
        <iframe
          src="https://assets.pinterest.com/ext/embed.html?id=12807180186888932"
          height="618"
          width="345"
          frameborder="0"
          scrolling="no"
          class="collage-image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import separadorImg from '@/assets/separador1.png';

export default {
  name: 'BienvenidaPage',
  setup() {
    const tex1Ref = ref(null);
    const bienvenidaRef = ref(null);
    const rightIconRef1 = ref(null);
    const rightIconRef2 = ref(null);
    const collageRef = ref(null);
    const text2Ref = ref(null);
    const text3Ref = ref(null);

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    }, {
      threshold: 0.1, // Activar cuando el 10% del elemento sea visible
    });

    onMounted(() => {
      nextTick(() => {
        // Asegurarse de observar todos los elementos que deben animarse
        if (tex1Ref.value) observer.observe(tex1Ref.value);  // Asegurar que tex1 se observe
        if (rightIconRef1.value) observer.observe(rightIconRef1.value);
        if (rightIconRef2.value) observer.observe(rightIconRef2.value);
        if (collageRef.value) observer.observe(collageRef.value);
        if (bienvenidaRef.value) observer.observe(bienvenidaRef.value);  // También observa el primer overlay
        if (text2Ref.value) observer.observe(text2Ref.value); // Observar texto2
        if (text3Ref.value) observer.observe(text3Ref.value); // Observar texto3
      });
    });

    return { 
      tex1Ref, 
      bienvenidaRef, 
      separadorImg, 
      rightIconRef1, 
      rightIconRef2, 
      collageRef, 
      text2Ref, 
      text3Ref 
    };
  }
};
</script>

<style scoped>
/* Contenedor Bienvenida */
.bienvenida-container {
  position: relative;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 20px;
  margin: 0;
  z-index: 1;
  font-family: 'Dancing Script', cursive;
  font-size: 26px;
  background: linear-gradient(to bottom, #ffffff, #f4f4f4, #ffffff);
  font-weight: 300;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: rgba(243,240,235,255); /* Ruta de la imagen de fondo */
  background-size: auto; /* Mantiene el tamaño real de la imagen */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  align-items: center;
  gap: 20px;
  box-sizing: border-box; /* Asegura que el padding no agregue más ancho */
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

/* Contenedor Overlay */
.overlay, .overlay2 {
  border-radius: 15px;
  width: 90%; /* Se ajusta al 90% del ancho de la pantalla */
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* Cambia a columna para que el contenido se apile verticalmente */
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Dancing Script', cursive;
  font-size: 32px;
  color: #917087d4;
  flex-direction: row; /* Alinea los iconos en una fila horizontal */
  margin-top:-50px;
}
.overlay2{
  margin-bottom: -10px;
}
/* Elementos dentro de overlay */
.tex1 {
  margin-top:30px;
  
  font-size: 38px;
  font-family: 'Dancing Script', sans-serif;
  font-weight: 350;    
  color: #333;
  font-size: 24px;
  text-align: center; /* Alinea el texto horizontalmente al centro */
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1s, transform 1s;
}

/* Animación de tex1 cuando se haga visible */
.visible.tex1 {
  opacity: 1;
  transform: translateX(0);
}

.text2 {
  font-size: 30px;
  font-weight: 400;
  margin-top:20px;
  font-family: 'FeelingPassionate';
  color: #1a1818cd;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 1s, transform 1s;
}

.text3 {
  font-size: 22px;
  font-weight: 400;
  font-family: 'Dancing Script', sans-serif;
  color: #1a1818cd;
  margin-left: 20px; /* Margen izquierdo */
  margin-right: 20px; /* Margen derecho */
  padding-left: 10px; /* Padding izquierdo */
  padding-right: 10px; /* Padding derecho */
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 1s, transform 1s;
}

/* Estilo del icono */
.icon {
  margin: 30px 0; /* Añade margen vertical a los iconos */
  opacity: 0;
  margin-top:40px;
  transform: scale(0);
  margin-bottom: 10px;
  justify-content: center; /* Centra el icono horizontalmente */
  align-items: center; /* Centra el icono verticalmente */
  transition: opacity 1s, transform 1s;
}

.icon-image {
  width: 50px;
  height: auto;
}

/* Animación de pulso */
@keyframes pulso {
  0%, 100% {
    transform: scale(1); /* Tamaño original */
  }
  50% {
    transform: scale(1.1); /* Aumenta el tamaño en un 10% */
  }
}

/* Aplicar animación de pulso */
.pulso {
  animation: pulso 1.5s infinite; /* Duración y que se repita infinitamente */
}

/* Centrar la imagen separador */
.separador-img {
  width: 100%;
  max-width: 50%; /* Cambia esto para hacerla más pequeña */
  height: auto;
  display: block;
  margin-top: 20px;
}

/* Contenedor del collage */
.collage {
  display: flex; /* Alinea las imágenes en fila */
  justify-content: space-between; /* Espacio entre las imágenes */
  gap: 10px; /* Agrega un pequeño espacio entre las imágenes */
  width: 80%; /* Ajusta el ancho al 90% del contenedor */
  max-width: 1000px; /* Limita el ancho máximo */
  margin: 10px auto; /* Centra el contenedor con márgenes automáticos en los lados */
  opacity: 0; /* Comienza invisible */
  transform: scale(0.8); /* Comienza con un tamaño más pequeño */
  transition: opacity 1s, transform 1s; /* Transiciones para hacer el cambio suave */
}

/* Estilo para cada imagen dentro del collage */
.collage .image-item {
  flex: 1; /* Hace que cada imagen ocupe el mismo espacio */
  max-width: 45%; /* Limita el tamaño máximo de las imágenes */
}

/* Cuando el collage se vuelve visible, aplica la animación */
.collage.visible {
  opacity: 1;
  transform: scale(1); /* Aumenta el tamaño a su tamaño original */
  transition: opacity 1s, transform 1s ease-out; /* Transición suave al tamaño original */
}


/* Imagen dentro del collage */
.collage-image {
  width: 100%;
  height: 100%;
  height: auto;

}

/* Cuando los elementos se hacen visibles */
.visible {
  opacity: 1;
  transform: translateX(0);
}
</style>
